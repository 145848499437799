import { UserRole } from 'src/app/core/graphql/generated/gen-types';

export const environment = {
	appVersion: require('../../package.json').version,
	hmr: false,
	debug: false,
	apiUrl: 'https://apollo.quickrelease.aero/graphql',
	wsUrl: 'wss://apollo.quickrelease.aero/graphql',
	baseHelpUrl: 'https://docs.quickrelease.aero/',
	production: true,
	identityPlatformUrl: 'https://identitytoolkit.googleapis.com/v1/',
	identityPlatformResetPasswordSuffix: 'accounts:sendOobCode?key=',
	identityPlatformSignInSuffix: 'accounts:signInWithPassword?key=',
	identityPlatformRefreshTokenSuffix: 'token?key=',
	identityPlatformApiKey: 'AIzaSyDgnSHoQOmb62o7krEAgL5HevwYWiQxUOs',
	upURL: 'https://up.quickrelease.aero/',
	icsUrl: 'https://calendar.quickrelease.aero/',
	// *************** TODO ****************** needs to be removed in a later stage
	firebaseConfig: {
		apiKey: 'AIzaSyDgnSHoQOmb62o7krEAgL5HevwYWiQxUOs',
		authDomain: 'qr-apollo.firebaseapp.com',
		projectId: 'qr-apollo',
		storageBucket: 'qr-apollo.appspot.com',
		messagingSenderId: '48469546037',
		appId: '1:48469546037:web:1d42aff52a05c783a38d7c',
		measurementId: 'G-R3MKM5T0FZ',
	},
	helphero: {
		appId: '684NBa0mOcb',
	},

	trackingCode: 'G-R3MKM5T0FZ',
	componentGuards: {
		dashboard: {
			meteo: [UserRole.Opr, UserRole.Met, UserRole.Ass, UserRole.Cre],
			flights: [UserRole.Opr, UserRole.Met, UserRole.Ass, UserRole.Cre],
			addFlights: [UserRole.Opr],
			bookings: [UserRole.Opr, UserRole.Ass],
			sms: [UserRole.Opr, UserRole.Ass],
			charts: [UserRole.Opr, UserRole.Cre],
		},
		dateCard: {
			plan: [UserRole.Opr],
		},
		flightCard: {
			edit: [UserRole.Opr, UserRole.Ass, UserRole.Met],
			checkIn: [UserRole.Opr, UserRole.Cre],
			bookings: [UserRole.Opr, UserRole.Ass],
		},
		flightList: {
			bookingsColumn: [UserRole.Opr, UserRole.Ass],
		},
		flightDetail: {
			crew: [UserRole.Opr, UserRole.Ass],
			bookings: [UserRole.Opr, UserRole.Ass],
			payments: [UserRole.Opr, UserRole.Ass],
			log: [UserRole.Opr, UserRole.Ass],
		},
		calendar: {
			event: [UserRole.Opr, UserRole.Ass],
			operator: [UserRole.Opr, UserRole.Ass, UserRole.Rep, UserRole.Met],
		},
		bookingDetail: {
			delete: [UserRole.Opr],
		},
		toolbar: {
			operator: [UserRole.Opr, UserRole.Ass],
		},
	},
};
